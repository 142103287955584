import { Injectable } from '@angular/core';
import {CommonsService} from "../../commons-service";
import {InstituicaoEnsino} from "./instituicao-ensino";

@Injectable({
  providedIn: 'root'
})
export class InstituicaoEnsinoService extends CommonsService<InstituicaoEnsino> {

  getPathModule(): string {
    return 'instituicaoensino';
  }
}
