import {CommonsService} from '../../commons-service'; 
import {Injectable} from "@angular/core";
import {CorpoProfissional} from './corpoprofissional';

@Injectable({ providedIn: 'root' }) 
export class CorpoprofissionalService extends CommonsService<CorpoProfissional> {

     getPathModule(): string { 
         return 'corpoprofissional';
     } 
} 
