import {CommonsService} from '../../commons-service';
import {Injectable} from "@angular/core";
import {Log} from './log';
import {Observable} from "rxjs/index";

@Injectable({ providedIn: 'root' })
export class LogService extends CommonsService<Log> {

  getPathModule(): string {
    return 'logauditoria';
  }

  public carregarDoProfissional(id: any): Observable<any> {
    const url = `${this.API_ROOT_URL}/${this.getPathModule()}/profissional/${id}`;
    return this.http.get(url);
  }
}
