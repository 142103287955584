import { Injectable } from '@angular/core';
import {CommonsService, ServerResponse} from "../../commons-service";
import {ProcessoEtico} from "./processo-etico";
import {HttpClient} from "@angular/common/http";
import {Observable} from "rxjs";

@Injectable({
  providedIn: 'root'
})
export class ProcessoEticoService extends CommonsService<ProcessoEtico> {

  constructor(public http: HttpClient) { super(http) }

  getPathModule(): string {
    return "processo-etico";
  }

  public loadAllPreProcesso(): Observable<ServerResponse<ProcessoEtico>> {
    return this.http.get<ServerResponse<ProcessoEtico>>(`${this.API_ROOT_URL}/${this.getPathModule()}/pre-processo/loadall`);
  }
}
