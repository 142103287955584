import {CommonsService} from '../../commons-service';
import {Injectable} from "@angular/core";
import { Prestador} from './prestador'

@Injectable({ providedIn: 'root' })
export class PrestadorService extends CommonsService<Prestador> {

     getPathModule(): string {
         return 'prestador';
     }
}
