import {CommonsService} from '../../commons-service';
import {Injectable} from "@angular/core";
import { AutoInfracaoItem } from './autoinfracaoitem'

@Injectable({ providedIn: 'root' }) 
export class AutoInfracaoItemService extends CommonsService<AutoInfracaoItem> {

     getPathModule(): string { 
         return 'autoinfracaoitem';
     } 
} 
